define("admin/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "logrocket", "admin/config/environment", "@chamaeleonidae/chmln"], function (_exports, _applicationRouteMixin, _logrocket, _environment, _chmln) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const blankTemplateRoutes = ['/subscription-offers', '/clients/organization/', '/capture_groups', '/missions/DBM'];
  var _default = _exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    routeAfterAuthentication: 'profile',
    model() {
      if (this.get('session.data.authenticated') && (_environment.default.logRocketKey || _environment.default.chameleonProjectKey)) {
        const adminUser = (({
          id,
          email,
          first_name,
          last_name
        }) => ({
          id,
          email,
          first_name,
          last_name
        }))(this.get('session.data.authenticated'));
        if (_environment.default.logRocketKey) {
          _logrocket.default.init(_environment.default.logRocketKey);
          _logrocket.default.identify(adminUser.id, {
            name: `${adminUser.first_name} ${adminUser.last_name}`,
            email: adminUser.email
          });
        }
        if (_environment.default.chameleonProjectKey) {
          _chmln.default.identify(`${adminUser.id}`, {
            name: `${adminUser.first_name} ${adminUser.last_name}`,
            first_name: adminUser.first_name,
            last_name: adminUser.last_name,
            email: adminUser.email,
            id: `${adminUser.id}`,
            role: 'Admin',
            type: 'Admin',
            isDroneBase: true,
            appId: 'admin'
          });
        }
      }
      return Ember.RSVP.hash({
        isBlankTemplate: blankTemplateRoutes.map(blankTemplateRoute => window.location.pathname.includes(blankTemplateRoute)).some(el => !!el)
      });
    },
    actions: {
      // eslint-disable-next-line object-shorthand
      openModal: function (modalName, model) {
        try {
          this.controllerFor(modalName).set('model', model);
        } catch (err) {
          this.generateController(modalName);
          this.controllerFor(modalName).set('model', model);
          this.controllerFor(modalName).set('actions', model.actions);
        }
        const savedScroll = document.documentElement.scrollTop || document.body.scrollTop;
        this.render(modalName, {
          into: 'application',
          outlet: 'modal'
        });
        const scrollTop = () => {
          Ember.$(document).scrollTop(savedScroll);
        };
        Ember.run.scheduleOnce('afterRender', scrollTop);
      },
      // eslint-disable-next-line object-shorthand
      closeModal: function () {
        return this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      },
      error: error => {
        var status;
        if (error.errors) {
          status = error.errors[0].status;
        }
        if (status === "0") {
          window.alert('We\'re having trouble connecting to the server. This problem is usually the result of a broken Internet connection. You can try refreshing this page.');
        } else if (status === "401") {
          (void 0).transitionTo('login');
        } else if (status === "403") {
          (void 0).transitionTo('/four-oh-three');
        } else if (status === "404") {
          (void 0).transitionTo('/four-oh-four');
        }
      }
    }
  });
});