define("admin/components/missions/plan-map/shotlist-shot", ["exports", "admin/data/shot-type/area-of-interest", "admin/data/shot-type/camera-requirements", "admin/data/shot-type/camera-sensor", "admin/data/shot-type/file-type", "admin/data/shot-type/level-of-detail", "admin/data/shot-type/pattern", "admin/data/shot-type/time-of-day", "admin/data/shot-type/asset-type", "admin/mixins/post-process-options"], function (_exports, _areaOfInterest, _cameraRequirements, _cameraSensor, _fileType, _levelOfDetail, _pattern, _timeOfDay, _assetType, _postProcessOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_postProcessOptions.default, {
    areaOfInterestOptions: _areaOfInterest.default,
    cameraRequirementOptions: _cameraRequirements.default,
    cameraSensorOptions: _cameraSensor.default,
    levelOfDetailOptions: _levelOfDetail.default,
    patternOptions: _pattern.default,
    timeOfDayOptions: _timeOfDay.default,
    assetTypes: _assetType.default,
    objectPostProcesses: Ember.computed.reads('shot.post_processes'),
    storePostProcesses: Ember.computed.reads('postProcesses'),
    postProcessLabel: 'nameShort',
    didReceiveAttrs() {
      this._super(...arguments);
      this.initProcessType();
    },
    fileTypeOptions: Ember.computed('shot.asset_type', function () {
      const fileType = this.get('shot.file_type');
      switch (this.get('shot.asset_type')) {
        case 'video':
          this.unsetChangesetProcessTypes(this.get('shot'));
          if (!_fileType.videoTypes.includes(fileType)) {
            this.set('shot.file_type', null);
          }
          return _fileType.default.video;
        case 'image':
          if (!_fileType.imageTypes.includes(fileType)) {
            this.set('shot.file_type', null);
          }
          return _fileType.default.image;
        default:
          return _fileType.default.default;
      }
    }),
    shotNameObserver: Ember.observer('shot.name', function () {
      if (this.get('errorName') && this.get('shot.name') && this.get('shot.name.length') > 0) {
        this.set('errorName', false);
      }
    }),
    saveButtonText: Ember.computed('shot', function () {
      if (this.get('shot.isNew')) {
        return 'Save';
      } else {
        return 'Update';
      }
    }),
    actions: {
      update(shot) {
        this.set('errorName', false);
        if (shot.get('name') && shot.get('name.length') > 0) {
          shot.save().then(() => {
            this.set('saved', true);
            Ember.run.later(() => this.set('saved', false), 3000);
          }).catch(function (error) {
            alert(`Failed to save the shot. ${error}`);
          });
        } else {
          this.set('errorName', true);
        }
      },
      remove(shot) {
        if (shot.get('isNew')) {
          shot.deleteRecord();
        } else {
          this.set('showDeleteShotModal', true);
        }
      },
      selectShot(shot) {
        this.set('activeShot', shot);
      },
      changeAssetType(value) {
        if (value === 'video') {
          this.unsetChangesetProcessTypes(this.get('shot'));
        } else if (value === 'image') {
          this.setChangesetProcessType(this.get('shot'));
        }
        return this.set('shot.asset_type', value);
      },
      togglePostProcess(postProcess) {
        this.toggleSelectedPostProcess(this.get('shot'), postProcess);
      }
    }
  });
});