define("admin/components/capture-groups/show", ["exports", "ember-changeset-validations", "admin/validations/capture-group", "ember-changeset", "admin/utils/send-error-to-sentry", "admin/utils/get-error-message-from-response", "admin/data/searches/mission_search_attributes"], function (_exports, _emberChangesetValidations, _captureGroup, _emberChangeset, _sendErrorToSentry, _getErrorMessageFromResponse, _mission_search_attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    NEW_LINE_CHAR: "\n",
    MISSIONS_SEARCH_AVAILABLE_ATTRIBUTES: Ember.A(['number', 'dbml']),
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    infinity: Ember.inject.service(),
    isPilotAssigningModalOpen: false,
    isPayoutsFormModalOpen: false,
    model: null,
    showPilotEditModal: false,
    activeTab: "details",
    activeInspectionsTab: "inspections",
    detailsAnchorIds: Ember.A(["pilots", "payouts", "capture_group_details", "internal_notes", "missions"]),
    attr: 'number',
    q: '',
    qInput: '',
    multiLineSearchOpened: false,
    init() {
      this._super(...arguments);
      this.changeset = new _emberChangeset.default(this.get('model.captureGroup'), (0, _emberChangesetValidations.default)(_captureGroup.default), _captureGroup.default);
    },
    wrappedStatuses: Ember.computed('model.statuses', function () {
      return this.get('model.statuses').map(status => {
        return Ember.Object.create({
          label: status.split('_').map(word => word.capitalize()).join(' '),
          value: status
        });
      });
    }),
    wrappedOperationOps: Ember.computed('model.operationReps,model.captureGroup.operation_rep', function () {
      return this.get('model.operationReps').map(rep => {
        return Ember.Object.create({
          label: `${rep.first_name} ${rep.last_name}`,
          value: rep.id,
          disabled: this.get('model.captureGroup.operation_rep') === rep
        });
      });
    }),
    nextStatus: Ember.computed('model.{captureGroup.status,statuses}', function () {
      const statusIndex = this.get('model.statuses').indexOf(this.get('model.captureGroup.status'));
      if (statusIndex >= 0) {
        return this.get('model.statuses')[statusIndex + 1];
      }
      return null;
    }),
    defaultCurrency: Ember.computed('model.{currencies.@each.code,defaultCurrencyCode}', function () {
      return this.get('model.currencies').findBy('code', this.get('model.defaultCurrencyCode'));
    }),
    // Missions search
    searchAttributesOptions: Ember.computed(function () {
      return _mission_search_attributes.default.filter(searchAttr => this.get('MISSIONS_SEARCH_AVAILABLE_ATTRIBUTES').includes(searchAttr.value));
    }),
    qOneline: Ember.computed('qCompacted', function () {
      return this.get('qCompacted').split(this.get('NEW_LINE_CHAR')).join(', ');
    }),
    qLinesCount: Ember.computed('qCompacted', function () {
      if (this.get('qCompacted')) {
        return this.get('qCompacted').split(this.get('NEW_LINE_CHAR')).length;
      } else {
        return 0;
      }
    }),
    qCompacted: Ember.computed('qInput', function () {
      return this.get('qInput').trim().replaceAll(/\n+/g, "\n");
    }),
    standbyHoursLink: Ember.computed('model.captureGroup.id', function () {
      return `/capture_groups/${this.get('model.captureGroup.id')}?activeTab=standbyHours`;
    }),
    submitSearch() {
      this.set('q', this.get('qInput'));
      this.fetchMissions();
    },
    recalculateTextareaHeight() {
      const textarea = document.querySelector('#searchTextarea');
      const valueNumberOfLineBreaks = (textarea.value.match(/\n/g) || []).length + 2; // +2 for more space
      const borderTop = parseInt(getComputedStyle(textarea).borderTopWidth, 10);
      const borderBottom = parseInt(getComputedStyle(textarea).borderBottomWidth, 10);
      const paddingTop = parseInt(getComputedStyle(textarea).paddingTop, 10);
      const paddingBottom = parseInt(getComputedStyle(textarea).paddingBottom, 10);
      const lineHeight = parseInt(getComputedStyle(textarea).lineHeight, 10);
      const minHeight = parseInt(getComputedStyle(textarea).minHeight, 10);
      const textHeight = valueNumberOfLineBreaks * lineHeight;
      if (textHeight > minHeight) {
        textarea.style.height = textHeight + borderTop + borderBottom + paddingTop + paddingBottom + 'px';
      } else {
        textarea.style.height = minHeight + 'px';
      }
    },
    async fetchMissions() {
      return this.set('model.missions', await this.infinity.model('mission', this.get('missionsQuery')));
    },
    missionsQuery: Ember.computed('attr', 'q', function () {
      return {
        capture_group_number: this.get('model.captureGroup.id'),
        startingPage: 1,
        perPage: 30,
        attr: this.get('attr'),
        q: this.get('q')
      };
    }),
    actions: {
      setTab(tab) {
        this.set('activeTab', tab);
        window.scrollTo({
          top: 0
        });
      },
      setSubTab(tabName) {
        this.set("activeSubTab", tabName);
      },
      setInspectionsTab(tabName) {
        this.set("activeInspectionsTab", tabName);
      },
      changeOperationRep(operationRepId) {
        this.changeset.set('operation_rep', this.get('model.operationReps').findBy('id', operationRepId));
      },
      toggleAssigningPilotModal() {
        this.set('isPilotAssigningModalOpen', !this.get('isPilotAssigningModalOpen'));
      },
      updateCaptureGroupStatus(nextStatus) {
        let adapter = this.store.adapterFor('capture-group');
        return adapter.updateStatus(this.get('sessionAccount.headers'), {
          status: nextStatus
        }, this.get('model.captureGroup')).then(() => {
          this.flashMessages.success('Capture Group was saved!');
          this.get('model.captureGroup').reload();
        }).catch(response => {
          (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
          this.flashMessages.error('Failed to save Capture Group');
        });
      },
      submit() {
        this.get('changeset').validate().then(() => {
          if (this.get('changeset.isValid')) {
            this.changeset.save().then(() => {
              this.flashMessages.success('Capture Group was saved!');
            }).catch(response => {
              (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
              this.flashMessages.error('Failed to save capture group');
            });
          }
        });
      },
      scrollToElementId(elementId, rootElementClass = null) {
        /* eslint-disable ember/no-global-jquery */
        /* eslint-disable no-undef */
        const rootOffset = Ember.$(`.${rootElementClass}`)?.offset()?.top || 0;
        const elementOffset = Ember.$(`#${elementId}`).offset().top;
        Ember.$(document).scrollTop(elementOffset - rootOffset);
        /* eslint-enable ember/no-global-jquery */
        /* eslint-enable no-undef */
        setTimeout(() => this.set('activeSubTab', elementId));
      },
      unassignPilot(pilot) {
        const captureGroup = this.get('model.captureGroup');
        this.store.adapterFor('capture-group').unassignPilot(this.get('sessionAccount.headers'), pilot, captureGroup).then(() => captureGroup.reload());
      },
      openPilotAssignmentModalAction() {
        this.openPilotAssignmentModal();
      },
      togglePayoutsFormModalAction() {
        this.set('isPayoutsFormModalOpen', !this.get('isPayoutsFormModalOpen'));
      },
      togglePilotEditModal(pilot = null) {
        this.set("selectedCGPilot", pilot);
        this.toggleProperty("showPilotEditModal");
      },
      openMultiLineSearch() {
        this.set('multiLineSearchOpened', true);
        Ember.run.schedule("afterRender", this, () => {
          this.recalculateTextareaHeight();
          document.querySelector('#searchTextarea').focus();
        });
      },
      selectSearchAttribute(value) {
        this.set('attr', value);
        this.fetchMissions();
      },
      closeMultiLineSearch() {
        this.set('multiLineSearchOpened', false);
      },
      textareaKeyUp() {
        this.recalculateTextareaHeight();
        Ember.run.debounce(this, this.submitSearch, 1000);
      },
      clearQuery() {
        this.set('q', '');
        this.set('qInput', '');
        this.fetchMissions();
      }
    }
  });
});