define("admin/components/missions/quality-check-summary-bar", ["exports", "admin/data/quality-check-issues"], function (_exports, _qualityCheckIssues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ALL_QC_ISSUES_SELECTED_OPTION = '__all__';
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    allQCIssuesSelectedOption: ALL_QC_ISSUES_SELECTED_OPTION,
    qualityCheckIssues: _qualityCheckIssues.default,
    selectedQCFilters: Ember.A([]),
    qualityCheckIssueOptions: Ember.computed('qualityCheckIssues', function () {
      return _qualityCheckIssues.default.mapBy('value');
    }),
    actions: {
      selectAsset(selectedOption, event) {
        event.stopPropagation();
        if (selectedOption === ALL_QC_ISSUES_SELECTED_OPTION) {
          const newQCFilters = this.selectedQCFilters.length === this.qualityCheckIssueOptions.length ? [] : this.qualityCheckIssueOptions;
          return this.onQCFiltersSelect(newQCFilters);
        }
        if (this.selectedQCFilters.includes(selectedOption)) {
          this.onQCFiltersSelect(this.selectedQCFilters.filter(filter => ![selectedOption].includes(filter)));
        } else {
          this.onQCFiltersSelect(Array.from(new Set([...this.selectedQCFilters, selectedOption])));
        }
      }
    }
  });
});