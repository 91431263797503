define("admin/data/dynamic-pricing-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    label: "Package Pricing",
    value: 'false'
  }, {
    label: "Dynamic Pricing (Catalog Items)",
    value: 'true'
  }];
});