define("admin/components/pilot-search-autocomplete", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotSearchAutocompleteComponent;
  PilotSearchAutocompleteComponent = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    results: [],
    pilotList: [],
    isAssignOperation: false,
    charLimitForQuery: 2,
    queryType: 'pilots',
    queryUri: {
      pilots: _environment.default.api.host + "/v1/admin/pilots.json"
    },
    distances: Ember.String.w('Any 25 50 100 250 1000 2000'),
    distance: '100',
    maxResultsOptions: Ember.String.w('10 20 50 100'),
    maxResults: '10',
    megaPixels: Ember.String.w('12 20'),
    selectedDrones: [],
    selectedCameras: [],
    selectedDevices: [],
    selectedEquipment: [],
    selectedDocuments: [],
    selectedCertificates: [],
    statuses: ['created', 'approved', 'evaluation_completed'],
    hasDrones: true,
    noDronebasePilots: _environment.default.filterOptions.filterDronebasePilots,
    availableForRelativeMission: true,
    classNames: ['pilot-search-autocomplete'],
    badgeSortProps: ['internal_name', 'asc'],
    droneSortProps: ['full_name', 'asc'],
    droneCameraOpen: false,
    sortAttribute: 'distance',
    autoDispatch: false,
    numPilotsToInvite: 0,
    numPilotsInvited: 0,
    confirmInvite: false,
    confirmAssign: false,
    previouslySelectedPilot: -1,
    sortDefinition: [],
    init: function () {
      this._super();
      this.set('selectedBadge', this.get('defaultBadge'));
      this.set('badgeId', this.get('defaultBadge.id'));
      this.set('selectedDrones', this.get('defaultDrones'));
      this.set('selectedCameras', this.get('defaultCameras'));
      this.set('selectedDevices', this.get('defaultDevices'));
      this.set('selectedEquipment', this.get('defaultEquipments'));
      this.set('selectedDocuments', this.get('defaultDocuments') || this.get('packageDocuments') || []);
      this.set('selectedCertificates', this.get('certificates') || []);
      this.set('isAssignOperation', this.get('isAssignOperation'));
      if (this.get("assign")) {
        this.get('distances').unshift('any');
        return this.set('distance', 'any');
      }
    },
    triggerSearch: function () {
      return Ember.run.debounce(this, 'processSearch', 1000);
    }.observes('query', 'distance', 'selectedMegaPixels', 'hasDrones', 'availableForRelativeMission', 'noDronebasePilots', 'badgeId', 'selectedDrones.[]', 'sortAttribute', 'maxResults', 'selectedCameras.[]', 'selectedEquipment.[]', 'selectedDevices.[]', 'selectedCertificates.[]', 'selectedDocuments.[]'),
    canAssignPilot: Ember.computed('model.mission.pilot_id', function () {
      return this.get('model.mission.pilot_id') === null;
    }),
    defaultBadge: Ember.computed('model.mission.package.requiredBadgeId', function () {
      if (this.get('model.mission.package.badge_required')) {
        return this.get('model.mission.package.badge');
      }
    }),
    defaultDrones: Ember.computed('model.mission.package.drones.[]', function () {
      return this.get('model.mission.package.drones') || [];
    }),
    defaultDevices: Ember.computed('model.mission.package.devices.[]', function () {
      return this.get('model.mission.package.devices') || [];
    }),
    defaultCameras: Ember.computed('model.mission.package.droneCameras.[]', function () {
      return this.get('model.mission.package.droneCameras') || [];
    }),
    defaultEquipments: Ember.computed('model.mission.package.pilotEquipments.[]', function () {
      return this.get('model.mission.package.pilotEquipments') || [];
    }),
    allowedLicenses: Ember.computed('model.licenses.[]', function () {
      var missionCountry;
      missionCountry = this.get('model.mission.location.country');
      if (this.get('model.licenses')) {
        return this.get('model.licenses').filter(function (license) {
          return license.country_codes.includes(missionCountry);
        });
      }
    }),
    certificates: Ember.computed('model.licenses.[]', function () {
      if (this.get('allowedLicenses')) {
        return this.get('allowedLicenses').filter(function (license) {
          return license.is_flight_certificate;
        });
      }
    }),
    defaultDocuments: Ember.computed('model.pilot_licenses.[]', function () {
      if (this.get('model.pilot_licenses.[]')) {
        return this.get('model.pilot_licenses.[]').filter(function (pilot_license) {
          return pilot_license.license.id;
        });
      }
    }),
    packageDocuments: Ember.computed('model.mission.package', function () {
      if (this.get('model.mission.package.compliance_documents.[]')) {
        return this.get('model.mission.package.compliance_documents.[]').filter(function (document) {
          return document.id;
        });
      }
    }),
    isScheduled: Ember.computed('model.mission.scheduled_at_start', 'model.mission.scheduled_at_end', function () {
      return this.get('model.mission.scheduled_at_start') && this.get('model.mission.scheduled_at_end');
    }),
    badges: Ember.computed.sort('model.badges', 'badgeSortProps'),
    drones: Ember.computed.sort('model.drones', 'droneSortProps'),
    selectedDronesObjects: Ember.computed('model.drones', 'selectedDrones.[]', function () {
      var selection;
      selection = this.get('selectedDrones');
      return this.get('model.drones').filter(function (drone) {
        return selection.indexOf("" + drone.id) !== -1;
      });
    }),
    cameras: Ember.computed('model.drones', function () {
      var availableCameraIds, cameras, seen;
      cameras = [];
      seen = {};
      availableCameraIds = [];
      this.get('model.drones').toArray().forEach(function (drone) {
        return drone.get('optional_cameras').forEach(function (camera) {
          if (!seen[camera.id]) {
            seen[camera.id] = true;
            cameras.push(camera);
            return availableCameraIds.push(camera.get('id'));
          }
        });
      });
      return cameras;
    }),
    phones: Ember.computed('model.devices', function () {
      return this.get('model.devices').filter(function (device) {
        return device.get('device_type') === 'phone';
      });
    }),
    tablets: Ember.computed('model.devices', function () {
      return this.get('model.devices').filter(function (device) {
        return device.get('device_type') === 'tablet';
      });
    }),
    checkboxName: function (val) {
      return val.toLowerCase().replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-');
    },
    sortedPilotList: Ember.computed.sort('results', 'sortDefinition'),
    pilotList: Ember.computed('sortedPilotList', function () {
      this.set('numPilotsToInvite', 0);
      return this.get('sortedPilotList').map(function (pilot) {
        pilot.set('invited', false);
        pilot.set('justInvited', false);
        return pilot;
      });
    }),
    defaultCameraMegapixels: Ember.computed('model.mission.package.camera_mega_pixels', function () {
      return this.get('model.mission.package.camera_mega_pixels');
    }),
    didInsertElement: function () {
      return this.set('query', '');
    },
    processSearch: function () {
      var distance, location, missionId, model, params, query;
      query = this.get('query');
      distance = this.get('distance');
      if (this.get('isAssignOperation')) {
        distance = 'Any';
      }
      model = this.get('model');
      location = this.get('model.mission.location');
      missionId = this.get('model.mission.id');
      params = {
        q: query,
        distance: distance,
        include: ['drones', 'drones.cameras', 'devices', 'pilot_equipment'],
        drone_ids: this.get('selectedDrones').getEach('id'),
        camera_ids: this.get('selectedCameras').getEach('id'),
        device_ids: this.get('selectedDevices').getEach('id'),
        document_ids: this.get('selectedDocuments').getEach('id'),
        pilot_certificate_ids: this.get('selectedCertificates').getEach('id'),
        pilot_equipment_ids: this.get('selectedEquipment').getEach('id'),
        statuses: this.get('statuses'),
        available_for_relative_mission: this.get('availableForRelativeMission'),
        no_dronebase_emails: this.get('noDronebasePilots'),
        has_drones: this.get('hasDrones'),
        per_page: this.get('maxResults'),
        relative_to_mission_id: missionId,
        badge_id: this.get('badgeId'),
        camera_mega_pixels: this.get('selectedMegaPixels'),
        sort_attribute: this.get('sortAttribute')
      };
      if (distance === 'any') {
        delete params.distance;
      }
      if (!query || query.length > this.charLimitForQuery) {
        this.set('results', []);
        return this.send('search', params, model);
      }
    },
    clearInviteError: function () {
      this.set('state', null);
      return this.set('message', null);
    },
    addInvitedPilot: function (pilot) {
      if (!pilot.get('invited')) {
        pilot.set('invited', true);
        return this.set('numPilotsToInvite', this.get('numPilotsToInvite') + 1);
      }
    },
    removeInvitedPilot: function (pilot) {
      if (pilot.get('invited')) {
        this.set('numPilotsToInvite', this.get('numPilotsToInvite') - 1);
        return pilot.set('invited', false);
      }
    },
    checkPilotInvited: function (pilot) {
      return this.get('sortedPilotList').find(function (p) {
        return p === pilot && p.invited;
      });
    },
    confirmAssignment: function (pilots) {
      var assignedPilot;
      assignedPilot = pilots.find(function (_this) {
        return function (pilot) {
          return pilot.invited;
        };
      }(this));
      return Ember.$.ajax({
        url: _environment.default.api.host + "/v1/admin/missions/" + this.get('model.mission.id') + "/pilot/" + assignedPilot.get('id') + "/assign",
        headers: this.get('sessionAccount.headers'),
        type: 'POST',
        dataType: 'json'
      }).then(function (_this) {
        return function (response) {
          _this.set('state', 'success');
          _this.set('message', 'Pilot successfully assigned');
          return window.location.reload(true);
        };
      }(this), function (_this) {
        return function (response) {
          _this.set('state', 'error');
          return _this.set('message', response.responseJSON.errors[0].detail);
        };
      }(this)).then(function () {
        _this.get('model.mission').reload();
        return _this.set('confirmAssign', false);
      });
    },
    sendInvites: function (pilots) {
      var _this, pilotData;
      _this = this;
      pilotData = pilots.map(function (pilot) {
        return _this.get('inviteData')(pilot);
      });
      return Ember.$.ajax({
        url: _environment.default.api.host + "/v1/admin/missions/" + this.get('model.mission.id') + "/notifications",
        headers: this.get('sessionAccount.headers'),
        type: 'POST',
        dataType: 'json',
        data: {
          sort_mode: this.get('sortAttribute'),
          isAssignOperation: this.get('isAssignOperation'),
          pilots: pilotData
        }
      }).then(function (_this) {
        return function (response) {
          return pilots.forEach(function (pilot) {
            pilot.set('justInvited', pilot.get('invited'));
            return pilot.set('invited', false);
          });
        };
      }(this), function (_this) {
        return function (response) {
          _this.set('state', 'error');
          return _this.set('message', response.responseJSON.errors[0].detail);
        };
      }(this)).then(function () {
        _this.get('model.mission').reload();
        _this.set('confirmInvite', false);
        _this.set('numPilotsInvited', _this.get('numPilotsToInvite'));
        return _this.set('numPilotsToInvite', 0);
      });
    },
    inviteData: function (pilot) {
      return {
        number: pilot.get('id'),
        score: pilot.get('score'),
        distance: pilot.get('distance'),
        invited: pilot.get('invited')
      };
    },
    actions: {
      setPresetFilter: function (val) {
        this.set('selectedDrones', []);
        this.set('selectedCameras', []);
        this.set('selectedDevices', []);
        $('.equipment-form input[type="checkbox"]').prop('checked', false);
        val.get('drones').forEach(function (_this) {
          return function (drone) {
            var selections;
            selections = _this.get('selectedDrones').without('a b c d').toArray();
            selections.push(drone);
            _this.set('selectedDrones', selections);
            return $("." + _this.checkboxName(drone.get('full_name'))).prop('checked', true);
          };
        }(this));
        return val.get('drone_cameras').forEach(function (_this) {
          return function (camera) {
            var selections;
            selections = _this.get('selectedCameras').without('a b c d').toArray();
            selections.push(camera);
            _this.set('selectedCameras', selections);
            return $("." + _this.checkboxName(camera.get('name'))).prop('checked', true);
          };
        }(this));
      },
      clearFilter: function () {
        $('select[name=preset-search-filter]')[0].selectedIndex = 0;
        $('.equipment-form input[type="checkbox"]').prop('checked', false);
        this.set('selectedDrones', this.get('defaultDrones'));
        this.set('selectedCameras', this.get('defaultCameras'));
        this.set('selectedDevices', this.get('defaultDevices'));
        this.get('selectedDrones').forEach(function (_this) {
          return function (drone) {
            return $("." + _this.checkboxName(drone.get('full_name'))).prop('checked', true);
          };
        }(this));
        this.get('selectedCameras').forEach(function (_this) {
          return function (camera) {
            return $("." + _this.checkboxName(camera.get('name'))).prop('checked', true);
          };
        }(this));
        return this.get('selectedDevices').forEach(function (_this) {
          return function (device) {
            return $("." + _this.checkboxName(device.get('name'))).prop('checked', true);
          };
        }(this));
      },
      toggleDroneList: function (event) {
        $($('#drone-header-toggle').data('target')).toggle();
        return this.set('droneCameraOpen', !this.get('droneCameraOpen'));
      },
      toggleDeviceList: function (event) {
        $($('#devices-header-toggle').data('target')).toggle();
        return this.set('devicesOpen', !this.get('devicesOpen'));
      },
      toggleEquipmentList: function (event) {
        $($('#other-equipment-header-toggle').data('target')).toggle();
        return this.set('equipmentOpen', !this.get('equipmentOpen'));
      },
      clearBadgeId: function () {
        $('select[name=badge-filter]')[0].selectedIndex = 0;
        return this.set('badgeId', null);
      },
      setBadgeId: function (val) {
        return this.set('badgeId', val.get('id'));
      },
      setDistance: function (val) {
        return this.set('distance', val);
      },
      setMaxResults: function (val) {
        return this.set('maxResults', val);
      },
      setMegaPixels: function (val) {
        return this.set('selectedMegaPixels', val);
      },
      search: function (query, model) {
        return this.search(query, model);
      },
      setSortBy: function (val) {
        var autoDispatch;
        this.clearInviteError();
        autoDispatch = val === 'score_auto_dispatch';
        this.set('autoDispatch', autoDispatch);
        if (autoDispatch) {
          this.set('maxResults', '24');
          this.set('distance', '100');
          return this.set('sortAttribute', 'score');
        } else {
          this.set('maxResults', '10');
          return this.set('sortAttribute', val);
        }
      },
      toggleIncludePilot: function (pilot, include, shiftKey) {
        var larger, newPilotIndex, pilotList, smaller;
        this.set('numPilotsInvited', 0);
        if (shiftKey && this.get('previouslySelectedPilot') >= 0) {
          pilotList = this.get('pilotList');
          newPilotIndex = pilotList.indexOf(pilot);
          if (newPilotIndex > this.get('previouslySelectedPilot')) {
            larger = newPilotIndex;
            smaller = this.get('previouslySelectedPilot');
          } else {
            larger = this.get('previouslySelectedPilot');
            smaller = newPilotIndex;
          }
          if (include) {
            while (smaller <= larger) {
              this.addInvitedPilot(pilotList[smaller]);
              smaller++;
            }
          } else {
            while (smaller <= larger) {
              this.removeInvitedPilot(pilotList[smaller]);
              smaller++;
            }
          }
        } else {
          if (include) {
            this.addInvitedPilot(pilot);
          } else {
            this.removeInvitedPilot(pilot);
          }
        }
        return this.set('previouslySelectedPilot', this.get('pilotList').indexOf(pilot));
      },
      clearInvites: function () {
        var i, len, pilot, ref;
        ref = this.get('pilotList');
        for (i = 0, len = ref.length; i < len; i++) {
          pilot = ref[i];
          pilot.set('invited', false);
        }
        this.set('numPilotsToInvite', 0);
        return this.set('confirmInvite', false);
      },
      clearAssignments: function () {
        var i, len, pilot, ref;
        ref = this.get('pilotList');
        for (i = 0, len = ref.length; i < len; i++) {
          pilot = ref[i];
          pilot.set('invited', false);
        }
        return this.set('confirmAssign', false);
      },
      invitePilots: function () {
        return this.set('confirmInvite', true);
      },
      assignPilot: function () {
        return this.set('confirmAssign', true);
      },
      cancelInvitePilots: function () {
        return this.set('confirmInvite', false);
      },
      cancelAssignPilot: function () {
        return this.set('confirmAssign', false);
      },
      confirmInvitePilots: function () {
        return this.sendInvites(this.get('sortedPilotList'));
      },
      confirmAssignPilot: function () {
        return this.confirmAssignment(this.get('sortedPilotList'));
      },
      autoDispatchPilots: function () {
        var _this;
        _this = this;
        return Ember.$.ajax({
          url: _environment.default.api.host + "/v1/admin/missions/" + this.get('model.mission.id') + "/notifications",
          headers: this.get('sessionAccount.headers'),
          type: 'POST',
          dataType: 'json',
          data: {
            auto_dispatch: true,
            has_drones: this.get('hasDrones'),
            no_dronebase_emails: this.get('noDronebasePilots'),
            drone_ids: this.get('selectedDrones').getEach('id'),
            camera_ids: this.get('selectedCameras').getEach('id'),
            device_ids: this.get('selectedDevices').getEach('id'),
            document_ids: this.get('selectedDocuments').getEach('id'),
            pilot_certificate_ids: this.get('selectedCertificates').getEach('id'),
            pilot_equipment_ids: this.get('selectedEquipment').getEach('id')
          }
        }).then(function (_this) {
          return function (response) {
            _this.set('state', 'success');
            _this.set('model.mission.pilot_invitations_dispatch', response.data.attributes);
            if (response.data.attributes['dispatch_status'] === 'in_progress') {
              _this.set('model.mission.status', 'pilots_notified');
              _this.set('message', 'Dispatching invitations to pilots');
              _this.controller.set('showInvitePilotLink', false);
              return _this.controller.set('invitationDispatchInProgress', true);
            } else if (response.data.attributes['dispatch_status'] === 'completed') {
              return _this.set('message', 'Sent invite to pilot');
            }
          };
        }(this), function (_this) {
          return function (response) {
            _this.set('state', 'error');
            return _this.set('message', response.responseJSON.errors[0].detail);
          };
        }(this));
      }
    }
  });
  var _default = _exports.default = PilotSearchAutocompleteComponent;
});