define("admin/helpers/join-dates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.joinDates = joinDates;
  function joinDates([dates]) {
    return dates.map((date, index) => date.date ? `${date.date} at ${date.min_time} - ${date.max_time}${index < dates.length - 1 ? ', ' : ''}` : '').join('');
  }
  var _default = _exports.default = Ember.Helper.helper(joinDates);
});