define("admin/components/reusable/catalog-items/link-to-edit-catalog-item-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    clientId: null,
    organizationId: null,
    packageNumber: null,
    catalogItemId: null,
    url: Ember.computed('clientId', 'packageNumber', function () {
      if (this.get('clientId')) {
        return `/clients/${this.get('clientId')}?package=${this.get('packageNumber')}&catalog_item=${this.get('catalogItemId')}`;
      } else {
        return `/clients/organizations/${this.get('organizationId')}?package=${this.get('packageNumber')}&catalog_item=${this.get('catalogItemId')}`;
      }
    })
  });
});