define("admin/components/clients/package-form", ["exports", "admin/data/cloud-options", "admin/data/sla-options", "admin/data/delivery-sla-starts-on-options", "admin/data/duration-options", "admin/data/dynamic-pricing-options", "admin/data/mission-order-flow-options", "admin/data/weekend-filter-options", "admin/utils/send-error-to-sentry", "admin/utils/get-error-message-from-response"], function (_exports, _cloudOptions, _slaOptions, _deliverySlaStartsOnOptions, _durationOptions, _dynamicPricingOptions, _missionOrderFlowOptions, _weekendFilterOptions, _sendErrorToSentry, _getErrorMessageFromResponse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO: rewrite with using changesets
  var _default = _exports.default = Ember.Component.extend({
    // flashMessages: service(),
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    tagName: "form",
    sortProperties: Ember.A(["vertical.short_name:asc", "price:asc", "name:asc"]),
    cloudOptions: _cloudOptions.default,
    dateCaptureOptions: _slaOptions.default,
    deliverySlaStartsOnOptions: _deliverySlaStartsOnOptions.default,
    durationOptions: _durationOptions.default,
    dynamicPricingOptions: _dynamicPricingOptions.default,
    orderFlowOptions: _missionOrderFlowOptions.default,
    weekendFilterOptions: _weekendFilterOptions.default,
    maxDescriptionLength: 180,
    currentlyEntered: 0,
    defaultCurrency: Ember.computed('model.currencies', function () {
      return this.get('model.currencies').find(currency => currency.code === 'USD');
    }),
    currencyOptions: Ember.computed('model.currencies', function () {
      return this.get('model.currencies').filterBy('pricing_allowed', true).map(currency => {
        return Ember.Object.create({
          label: `${currency.symbol} ${currency.code}`,
          value: currency.code,
          disabled: !currency.pricing_allowed
        });
      });
    }),
    init() {
      this._super();
      if (!this.get('model.package.currency.id')) {
        this.get('model.package').set('currency', this.get('defaultCurrency'));
      }
    },
    wrappedDeliverySlaOptions: Ember.computed('dateCaptureOptions', function () {
      let options = this.get('dateCaptureOptions').map(({
        name,
        value
      }) => {
        return Ember.Object.create({
          label: name,
          value: value.toString()
        });
      });
      options.unshift(Ember.Object.create({
        label: 'No SLA',
        value: ''
      }));
      return options;
    }),
    wrappedDateCaptureWithinOptions: Ember.computed('dateCaptureOptions', function () {
      let options = this.get('dateCaptureOptions').map(({
        name,
        value
      }) => {
        return Ember.Object.create({
          label: name,
          value: value.toString()
        });
      });
      options.unshift(Ember.Object.create({
        label: 'No SLA',
        value: ''
      }));
      return options;
    }),
    wrappedDateCaptureOffsetOptions: Ember.computed('dateCaptureOptions', function () {
      let options = this.get('dateCaptureOptions').map(({
        name,
        value
      }) => {
        return Ember.Object.create({
          label: name,
          value: value.toString(value)
        });
      });
      options.unshift(Ember.Object.create({
        label: 'No Offset',
        value: 0
      }));
      return options;
    }),
    selectedAddons: Ember.computed('model.package.addons.[]', function () {
      return this.get('model.package.addons');
    }),
    defaultDocuments: Ember.computed('model.pilot_licenses.[]', function () {
      if (this.get('model.pilot_licenses.[]')) {
        return this.get('model.pilot_licenses.[]').filter(pilot_license => pilot_license.license.id);
      }
      return [];
    }),
    disableOnEdit: Ember.computed('model.package.isNew', function () {
      return !this.get('model.package.isNew');
    }),
    submitLabel: Ember.computed('model.package', function () {
      if (this.get('model.package.isNew')) {
        return "Create";
      } else {
        return "Update";
      }
    }),
    availableAddons: Ember.computed('model.{client.available_addons,organization.available_addons}', function () {
      if (Ember.isPresent(this.get('model.organization'))) {
        return this.get('model.organization.available_addons');
      } else {
        return this.get('model.client.available_addons');
      }
    }),
    isAddonsAny: Ember.computed('availableAddons.[]', function () {
      return Ember.isPresent(this.get('availableAddons'));
    }),
    withDynamicPricing: Ember.computed('model.package.dynamic_pricing', function () {
      return this.get('model.package.dynamic_pricing');
    }),
    drones: Ember.computed('model.drones', function () {
      return this.get('model.drones').sortBy('full_name');
    }),
    productionDeliverables: Ember.computed('model.work_types', function () {
      return this.get('model.work_types').filterBy('process_type', 'production');
    }),
    windDeliverables: Ember.computed('model.work_types', function () {
      return this.get('model.work_types').filterBy('process_type', "wind");
    }),
    solarDeliverables: Ember.computed('model.work_types', function () {
      return this.get('model.work_types').filterBy('process_type', "solar");
    }),
    otherDeliverables: Ember.computed('model.work_types', function () {
      return this.get('model.work_types').filterBy('process_type', "other");
    }),
    telecomDeliverables: Ember.computed('model.work_types', function () {
      return this.get('model.work_types').filterBy('process_type', "telecom");
    }),
    dronebasePackagesSorted: Ember.computed.sort("model.dronebase_packages", "sortProperties"),
    showDronebasePack: Ember.computed('disableOnEdit', 'mode', function () {
      return this.get('mode') !== 'Client' && !this.get('disableOnEdit');
    }),
    // eslint-disable-next-line ember/no-observers
    packageDescriptionObserver: Ember.observer('model.package.description.length', function () {
      if (this.get('model.package.description.length') > this.get('maxDescriptionLength')) {
        return this.set('model.package.description', this.get('model.package.description').slice(0, this.get('maxDescriptionLength')));
      } else {
        return this.set('currentlyEntered', this.get('model.package.description.length'));
      }
    }),
    submit(e) {
      e.preventDefault();
      this.set('model.package.addons', this.get('selectedAddons'));
      let mfa = this.get('model.package.package_flight_app');
      this.get('model.package').save().then(pkg => {
        this.set('model.package', pkg);
        if (!mfa) {
          this.savePackageAction(this.get('model'));
          return;
        }
        if (mfa.get('flight_app.deliver_to_app') !== 'external_url') {
          mfa.set('delivery_to_url', null);
        }
        mfa.save().then(() => {
          return this.savePackageAction(this.get('model'));
        }, response => {
          alert("Package was saved, but not the 3PFA workflow");
          response.errors[0].detail.split(', ').forEach(error => {
            return this.set(error.split(' ')[0].toLowerCase() + 'Error', error);
          });
        }).catch(response => {
          (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
          return alert("Failed to save 3pfa");
        });
      }).catch(response => {
        (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
        return alert('Failed to save the package');
      });
    },
    // eslint-disable-next-line ember/no-observers
    selectedDronebasePackageObserver: Ember.observer('selectedDronebasePackage', function () {
      let dronebasePack = this.get('selectedDronebasePackage').toJSON();
      let hasManyRelationships = [['catalog_items', 'catalog_item'], ['devices', 'device'], ['drones', 'drone'], ['compliance_documents', 'compliance_document'], ['droneCameras', 'drone_camera'], ['pilotEquipments', 'pilot_equipment'], ['addons', 'package-addon'], ['deliverableWorkTypes', 'deliverable_work_type']];
      let belongsToRelationships = ['vertical', 'shot_list', 'accountRep', 'badge', 'currency'];
      hasManyRelationships.forEach(relation => {
        let values = [];
        dronebasePack[relation[0]].forEach(id => {
          values.push(this.get('model.package.store').peekRecord(relation[1], id));
        });
        return dronebasePack[relation[0]] = values;
      });
      this.get('model.package').setProperties(dronebasePack);
      belongsToRelationships.forEach(relation => {
        return this.set("model.package." + relation, this.get("selectedDronebasePackage." + relation));
      });
      this.set('model.package.organization', this.get('model.organization'));
      return this.set('model.package.slug', null);
    }),
    actions: {
      setDynamicPricing(value) {
        return this.set('model.package.dynamic_pricing', value === 'true');
      },
      setWeekendFilter(choice) {
        this.set('model.package.weekend_schedule_filter', choice);
        if (choice === 'weekends_only') {
          this.set('model.package.data_capture_within', '');
          return this.set('model.package.data_capture_offset', '');
        }
      },
      setDeliverySlaStartsOnOptions(value) {
        this.set('model.package.deliverySlaStartsOnOptions', value);
      },
      setMegaPixels(val) {
        return this.set('model.package.camera_mega_pixels', val);
      },
      setShotList(value) {
        return this.set('model.package.shot_list', this.get('model.shotListsForSelect').findBy('id', value));
      },
      closeModal() {
        if (this.get('model.package.hasDirtyAttributes')) {
          if (this.get('model.package.hasDirtyAttributes')) {
            this.get('model.package').rollbackAttributes();
          } else {
            this.get('model.package').destroy();
          }
        }
        return this.closeModalAction();
      },
      toggleAutoDispatch() {
        return this.toggleProperty('model.package.auto_dispatch_enabled');
      },
      changeBadge(val) {
        this.set('model.package.badge', val);
        if (val === void 0) {
          return this.set('model.package.badge_required', false);
        }
      },
      saveDescriptionBulletPoint() {
        let newValue = this.get('newDescriptionBulletPoint');
        if (newValue.length > 0) {
          this.set('newDescriptionBulletPoint', '');
          return this.get('model.package.description_bullet_points').pushObject(newValue);
        }
      },
      removeItem(itemIndex) {
        return this.get('model.package.description_bullet_points').removeAt(itemIndex);
      },
      setOrderFlow(value) {
        return this.set('model.package.order_flow', value);
      },
      changePackageCurrency(currencyCode) {
        this.get('model.package').set('currency', this.get('model.currencies').findBy('code', currencyCode) || this.get('defaultCurrency'));
      },
      destroyCatalogItem(catalogItem) {
        return this.store.adapterFor('catalog-item').destroy(catalogItem.id, this.get('sessionAccount.headers')).then(() => {
          this.get('model.package.all_catalog_items').removeObject(catalogItem);
          this.flashMessages.success('Catalog Item was deleted!');
        }).catch(response => {
          (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
          this.flashMessages.error('Failed to delete Catalog Item');
        });
      }
    }
  });
});