define("admin/components/mission-flight-app", ["exports", "admin/config/environment", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _environment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MissionFlightAppComponent = Ember.Component.extend(_fileSaver.default, {
    classNames: ["mission-flight-app row panel-group"],
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    workflowModeOverride: null,
    hasCyberduckCredentials: true,
    didInsertElement() {
      if (this.get('missionFlightApp.flight_app.deliver_to_app') !== 'cyberduck' || this.get('missionFlightApp.value.access_id') == null || this.get('missionFlightApp.value.secret_key') == null) {
        return this.set('hasCyberduckCredentials', false);
      }
    },
    groupedFlightApps: Ember.computed('model.flightApps', function () {
      const output = {};
      this.get('model.flightApps').forEach(function (flightApp) {
        const key = flightApp.get('name') + '-' + flightApp.get('data_type');
        if (output[key]) {
          return output[key]['apps'].push(flightApp);
        } else {
          return output[key] = {
            name: flightApp.get('name'),
            dataType: flightApp.get('data_type'),
            apps: [flightApp]
          };
        }
      });
      const values = Object.values(output);
      if (!this.get('activeFlightAppGroup')) {
        this.set('activeFlightAppGroup', values[0]);
      }
      return values.sort(function (a, b) {
        const textA = a.name;
        const textB = b.name;
        return textA.localeCompare(textB);
      });
    }),
    workflowMode: Ember.computed('missionFlightApp', function () {
      if (this.get('missionFlightApp')) {
        return 'create';
      } else {
        return 'none';
      }
    }),
    requireCyberduck: Ember.computed('missionFlightApp', 'missionFlightApp.flight_app', function () {
      return this.get('missionFlightApp.flight_app.deliver_to_app') === 'cyberduck';
    }),
    isMatterporkLink: Ember.computed('missionFlightApp', 'missionFlightApp.flight_app', function () {
      return this.get('missionFlightApp.flight_app.slug') === 'matterport_link';
    }),
    requireExternalUrl: Ember.computed('missionFlightApp', 'missionFlightApp.flight_app', function () {
      return this.get('missionFlightApp.flight_app.deliver_to_app') === 'external_url';
    }),
    missionFlightApp: Ember.computed('model.mission.mission_flight_app', 'transientMissionFlightApp', function () {
      if (this.get('model.mission.mission_flight_app')) {
        return this.get('model.mission.mission_flight_app');
      } else {
        return this.get('transientMissionFlightApp');
      }
    }),
    valueErrorObserver: Ember.observer('valueError', 'missionFlightApp.value.android', 'missionFlightApp.value.ios', 'missionFlightApp.value.external_id', function () {
      const valuePresent = this.get('missionFlightApp.value.android') || this.get('missionFlightApp.value.ios') || this.get('missionFlightApp.value.external_id');
      if (this.get('valueError') && valuePresent) {
        return this.set('valueError', !valuePresent);
      }
    }),
    deliveryErrorObserver: Ember.observer('deliveryError', 'isMatterporkLink', 'missionFlightApp.delivery_to_url', function () {
      const deliveryPresent = this.get('missionFlightApp.delivery_to_url');
      const deliveryLength = deliveryPresent && deliveryPresent.length < 255 || !this.get('isMatterporkLink');
      const deliveryDelimiter = deliveryPresent && deliveryPresent.split("  ").every(url => url.split(" ").length == 1) || !this.get('isMatterporkLink');
      if (deliveryPresent && deliveryLength && deliveryDelimiter) {
        return this.set('deliveryError', false);
      }
    }),
    matterportCharactersRemaining: Ember.computed('missionFlightApp', 'missionFlightApp.delivery_to_url', function () {
      let deliveryUrls = this.get('missionFlightApp.delivery_to_url') || '';
      return deliveryUrls.length < 255 ? 255 - deliveryUrls.length : 0;
    }),
    actions: {
      setEditorMode(value) {
        let mfa;
        if (value === 'none') {
          if ((mfa = this.get('missionFlightApp')) && mfa.id) {
            if (confirm(`Are you sure you want to delete the workflow for this mission? ${mfa.get('mission.id')}`)) {
              mfa.set('mission', this.get('model.mission'));
              this.get('model.mission').set('mission_flight_app', mfa);
              return mfa.destroyRecord().then(() => {
                this.get('model.mission').set('mission_flight_app', null);
                this.set('transientMissionFlightApp', null);
                return alert("Workflow has been deleted");
              }, () => alert("Failed to delete Workflow"));
            } else {
              return Ember.$(this.element).find('[name=workflow-mode][value=create]').click();
            }
          } else {
            return this.set('transientMissionFlightApp', null);
          }
        } else {
          if (!this.get('model.mission.mission_flight_app')) {
            console.log('creating a transient mission flight app');
            mfa = this.get('store').createRecord('mission_flight_app');
            mfa.set('value', {});
            mfa.set('flight_app', this.get('model.flightApps.firstObject'));
            return this.set('transientMissionFlightApp', mfa);
          }
        }
      },
      setAppGroup(elem) {
        console.clear();
        const value = parseInt(elem.target.value);
        const selection = this.get('groupedFlightApps').objectAt(value);
        this.set('activeFlightAppGroup', selection);
        console.log('app group items', selection.apps);
        const currentFlightAppUpload = this.get('missionFlightApp.flight_app.deliver_to_app');
        const equivalentUploadMethod = selection.apps.filter(function (app) {
          console.log(app.get('deliver_to_app'), currentFlightAppUpload);
          return app.get('deliver_to_app') === currentFlightAppUpload;
        })[0];
        console.log('equivalent upload method', equivalentUploadMethod);
        return this.get('missionFlightApp').set('flight_app', equivalentUploadMethod || selection.apps.objectAt(0));
      },
      setFlightApp(elem) {
        const value = parseInt(elem.target.value);
        let selection = this.get('model.flightApps').filter(app => parseInt(app.get('id')) === value)[0];
        console.log('value', value);
        console.log('setFlightApp', selection);
        if (!selection) {
          selection = this.get('model.flightApps')[0];
        }
        return this.get('missionFlightApp').set('flight_app', selection);
      },
      submit() {
        const mfa = this.get('missionFlightApp');
        mfa.set('mission', this.get('model.mission'));
        if (mfa.get('flight_app.deliver_to_app') !== 'external_url') {
          mfa.set('delivery_to_url', null);
        }
        return mfa.save().then(() => alert("Workflow Saved"), response => {
          console.log(response.errors);
          return response.errors[0].detail.split(', ').forEach(error => {
            const field = error.split(' ')[0].toLowerCase();
            return this.set(field + 'Error', error);
          });
        });
      },
      generateCredentials(mission) {
        Ember.$('.cyberduck-credentials .error').hide();
        return Ember.$.ajax({
          url: `${_environment.default.api.host}/v1/admin/missions/${mission.id}/cyberduck`,
          type: 'POST',
          dataType: 'json',
          headers: this.get('sessionAccount.headers')
        }).then(data => {
          this.get('model.mission').reload();
          return this.set('hasCyberduckCredentials', true);
        }, error => {
          return Ember.$('.cyberduck-credentials .error').show();
        });
      },
      resetCyberduck(mission) {
        const really_delete = confirm('Delete all assets and set mission to pilot_accepted?');
        if (!really_delete) {
          return;
        }
        Ember.$('.cyberduck-reset .error').hide();
        return Ember.$.ajax({
          url: `${_environment.default.api.host}/v1/admin/missions/${mission.id}/cyberduck/reset`,
          type: 'DELETE',
          dataType: 'json',
          headers: this.get('sessionAccount.headers')
        }).then(data => {
          return window.location.reload(true);
        }, error => {
          return Ember.$('.cyberduck-reset .error').show();
        });
      },
      getDuckFile(mission) {
        return Ember.$.ajax({
          url: `${_environment.default.api.host}/v1/admin/missions/${mission.id}/cyberduck`,
          type: 'GET',
          headers: this.get('sessionAccount.headers')
        }).then(response => {
          let blob;
          try {
            blob = new Blob([response], {
              type: 'application/octet-stream'
            });
          } catch (error) {
            window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder || window.MSBlobBuilder;
            if (error.name === 'TypeError' && window.BlobBuilder) {
              const bb = new BlobBuilder();
              bb.append(response);
              blob = bb.getBlob("application/octet-stream");
            } else if (error.name === 'InvalidStateError') {
              blob = new Blob([response], {
                type: 'application/octet-stream'
              });
            } else {
              alert('Downloading is not supported on your device.');
            }
          }
          const filename = mission.id + '.duck';
          return this.saveFileAs(filename, blob, 'application/octet-stream');
        });
      }
    }
  });
  var _default = _exports.default = MissionFlightAppComponent;
});