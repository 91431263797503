define("admin/adapters/catalog-item", ["exports", "admin/adapters/application", "admin/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    namespace: _environment.default.api.namespace + '/admin',
    destroy: (catalogItemId, headers) => {
      return Ember.$.ajax({
        url: `${_environment.default.api.host}/admin/catalog_items/${catalogItemId}`,
        method: 'DELETE',
        dataType: 'json',
        headers
      });
    }
  });
});