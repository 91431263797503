define("admin/mixins/post-process-options", ["exports", "admin/utils/ember-changeset", "admin/models/post-process"], function (_exports, _emberChangeset, _postProcess) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    _previousProcessType: [],
    // to remove previous value

    initProcessType() {
      if (!!this.get('objectPostProcesses')) {
        const currentProcessType = this.get('objectPostProcesses').filter(type => _postProcess.IMG_POST_PROCESSES.includes(type.process_type)).sortBy('id').map(postProcess => {
          return Ember.Object.create({
            label: postProcess.get(this.postProcessLabel),
            value: postProcess
          });
        }) || [];
        this.set('_previousProcessType', currentProcessType.map(postProcess => postProcess.value.id));
      }
    },
    postProcessTypesOptions: Ember.computed('storePostProcesses', function () {
      let options = Ember.A([]);
      options.pushObjects(this.get('postProcesses').filter(type => _postProcess.IMG_POST_PROCESSES.includes(type.process_type)).sortBy('id').map(postProcess => {
        return Ember.Object.create({
          label: postProcess.get(this.postProcessLabel),
          value: postProcess
        });
      }));
      return options;
    }),
    unsetChangesetProcessTypes(changeset) {
      this.clearChangesetProcessTypes(changeset);
    },
    clearChangesetProcessTypes(changeset) {
      const previousPostProcesses = this.get('storePostProcesses').filter(type => _postProcess.IMG_POST_PROCESSES.includes(type.get('process_type')));
      previousPostProcesses.forEach(previousPostProcess => {
        (0, _emberChangeset.removeFromHasMany)(changeset, 'post_processes', previousPostProcess);
      });
    },
    setChangesetProcessType(changeset) {
      const previousIds = this.get('_previousProcessType');
      const previousPostProcesses = this.get('storePostProcesses').filter(type => previousIds.includes(type.id));
      previousPostProcesses.forEach(previousPostProcess => (0, _emberChangeset.addToHasMany)(changeset, 'post_processes', previousPostProcess));
    },
    toggleSelectedPostProcess(object, postProcess) {
      if (object.get('post_processes').includes(postProcess)) {
        (0, _emberChangeset.removeFromHasMany)(object, 'post_processes', postProcess);
        this.set('_previousProcessType', this.get('_previousProcessType').filter(id => id !== postProcess.id));
      } else {
        (0, _emberChangeset.addToHasMany)(object, 'post_processes', postProcess);
        this.set('_previousProcessType', this.get('_previousProcessType').concat(postProcess.id));
      }
    }
  });
});