define("admin/data/pilot-account-type-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    label: 'Pilot',
    value: 'Pilot'
  }, {
    label: 'Analyst',
    value: 'Analyst'
  }];
});