define("admin/models/pilot", ["exports", "ember-data", "admin/models/rescheduler", "admin/mixins/user"], function (_exports, _emberData, _rescheduler, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _rescheduler.default.extend(_user.default, {
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    birthday: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    postal_code: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    travel_distance: _emberData.default.attr('number'),
    drone_system: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    approve: _emberData.default.attr('boolean'),
    reject: _emberData.default.attr('boolean'),
    password: _emberData.default.attr('string'),
    payout: _emberData.default.attr('string'),
    sorted_on: _emberData.default.attr(),
    paypal_email: _emberData.default.attr('string'),
    payment_processor: _emberData.default.attr('string'),
    autopay_enabled: _emberData.default.attr('boolean'),
    timezone_id: _emberData.default.attr('string'),
    is_available_weekdays: _emberData.default.attr('boolean'),
    is_available_weekends: _emberData.default.attr('boolean'),
    average_rating: _emberData.default.attr('number'),
    completed_missions_count: _emberData.default.attr('number'),
    checkr_status: _emberData.default.attr('string'),
    checkr_package: _emberData.default.attr('string'),
    stripe_enabled: _emberData.default.attr('boolean'),
    account_type: _emberData.default.attr('string'),
    pilotEquipment: _emberData.default.hasMany('pilot_equipment', {
      async: false
    }),
    devices: _emberData.default.hasMany('device', {
      async: false
    }),
    drones: _emberData.default.hasMany('pilot_drone', {
      async: false
    }),
    pilot_licenses: _emberData.default.hasMany('license', {
      async: false
    }),
    pilot_badges: _emberData.default.hasMany('pilot_badge', {
      async: false
    }),
    currency: _emberData.default.belongsTo('currency', {
      async: false
    }),
    pilot_rates: _emberData.default.hasMany('pilot_rate', {
      async: false
    }),
    payouts: _emberData.default.hasMany('payouts', {
      async: false
    }),
    capture_groups: _emberData.default.hasMany('capture-group', {
      async: false
    }),
    pilot_search_metum: _emberData.default.belongsTo('pilot_search_metum', {
      async: false
    }),
    standby_hours_line_items: _emberData.default.hasMany('standby-hours-line-item', {
      async: false
    }),
    stripe_accounts: _emberData.default.hasMany('stripe_account', {
      async: false
    }),
    site_inspection_pilots: _emberData.default.hasMany('site-inspection-pilot'),
    capture_group_pilots: _emberData.default.hasMany('capture-group-pilot'),
    missions: _emberData.default.hasMany('mission'),
    approved: Ember.computed('status', function () {
      return this.get('status') === 'approved';
    }),
    camerasArray: Ember.computed('drones.[]', function () {
      return this.get('drones').map(function (drone) {
        return drone.get('cameras').toArray();
      }).reduce(function (acc, val) {
        return acc.concat(val);
      }, []);
    }),
    distance: Ember.computed('sorted_on', function () {
      const sorted_on = this.get('sorted_on');
      if (sorted_on) {
        return sorted_on.distance;
      }
      return;
    }),
    score: Ember.computed('sorted_on', function () {
      const sorted_on = this.get('sorted_on');
      if (sorted_on) {
        return sorted_on.score;
      }
      return;
    }),
    fullName: Ember.computed('first_name', 'last_name', function () {
      return `${this.get('first_name')} ${this.get('last_name')}`;
    }),
    payoutInDollars: Ember.computed('total_payout', function () {
      return this.get('payout') / 100;
    }),
    formattedAddress: Ember.computed('address', 'address2', 'city', 'state', 'postal_code', function () {
      const address_variables = [this.get('address'), this.get('address2'), this.get('city'), this.get('state'), this.get('postal_code')];
      return address_variables.filter(function (el) {
        return el != null && el != '';
      }).join(', ');
    }),
    shortFormattedAddress: Ember.computed('city', 'state', 'postal_code', function () {
      const address_variables = [this.get('city'), this.get('state'), this.get('postal_code')];
      return address_variables.filter(function (el) {
        return el != null && el != '';
      }).join(', ');
    })
  });
});