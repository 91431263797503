define("admin/templates/components/reusable/catalog-items/link-to-new-catalog-item-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6pXHvLsf",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[14,0,\"w-auto p-4 zv-button default secondary large unstyled-link\"],[15,6,[30,[36,1],[[35,0]],null]],[12],[2,\"\\n  ADD CATALOG ITEM\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"url\",\"link-to-react-app\"]}",
    "meta": {
      "moduleName": "admin/templates/components/reusable/catalog-items/link-to-new-catalog-item-form.hbs"
    }
  });
});