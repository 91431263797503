define("admin/models/package", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    number: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    price: _emberData.default.attr('string'),
    currency: _emberData.default.belongsTo('currency'),
    description: _emberData.default.attr('string'),
    slug: _emberData.default.attr('string'),
    mission_instructions: _emberData.default.attr('string'),
    mission_internal_notes: _emberData.default.attr('string'),
    mission_production_notes: _emberData.default.attr('string'),
    default_pilot_script: _emberData.default.attr('string'),
    estimated_pilot_payout: _emberData.default.attr('string'),
    estimated_analyst_payout: _emberData.default.attr('string'),
    estimated_roof_measurement_payout: _emberData.default.attr('string'),
    salesforce_opportunity_id: _emberData.default.attr('string'),
    vertical: _emberData.default.belongsTo('vertical', {
      async: false
    }),
    organization: _emberData.default.belongsTo('organization', {
      async: true
    }),
    shot_list: _emberData.default.belongsTo('shot_list', {
      async: true
    }),
    client: _emberData.default.belongsTo('client', {
      async: true
    }),
    accountRep: _emberData.default.belongsTo('admin', {
      async: false
    }),
    devices: _emberData.default.hasMany("device"),
    droneCameras: _emberData.default.hasMany("drone_camera"),
    drones: _emberData.default.hasMany("drone"),
    compliance_documents: _emberData.default.hasMany("license"),
    pilotEquipments: _emberData.default.hasMany("pilot_equipment"),
    deliverableWorkTypes: _emberData.default.hasMany("deliverable_work_type"),
    badge: _emberData.default.belongsTo('badge', {
      async: false
    }),
    package_flight_app: _emberData.default.belongsTo('package_flight_app', {
      async: false
    }),
    catalog_items: _emberData.default.hasMany('catalog_items', {
      async: false
    }),
    // NOTE: active only here
    all_catalog_items: _emberData.default.hasMany('catalog_items', {
      async: false
    }),
    // NOTE: only to display on package form

    dynamic_pricing: _emberData.default.attr('boolean'),
    follow_up_with_pilot_after_accepted: _emberData.default.attr('boolean'),
    require_contact_to_reschedule: _emberData.default.attr('boolean'),
    pointivo_package_id: _emberData.default.attr('string'),
    order_flow: _emberData.default.attr('string', {
      defaultValue: 'standard'
    }),
    require_raw_images: _emberData.default.attr('boolean'),
    badge_required: _emberData.default.attr('boolean'),
    auto_dispatch_enabled: _emberData.default.attr('boolean'),
    camera_mega_pixels: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    cloud_reqs: _emberData.default.attr('string', {
      defaultValue: 'less_than_50'
    }),
    timeframe_start: _emberData.default.attr('string', {
      defaultValue: '660'
    }),
    timeframe_end: _emberData.default.attr('string', {
      defaultValue: '900'
    }),
    data_capture_within: _emberData.default.attr('string', {
      defaultValue: '72'
    }),
    data_capture_offset: _emberData.default.attr('string'),
    delivery_sla: _emberData.default.attr('string'),
    delivery_sla_starts_on: _emberData.default.attr('string'),
    weekend_schedule_filter: _emberData.default.attr('string', {
      defaultValue: 'any_day'
    }),
    mission_duration: _emberData.default.attr('string', {
      defaultValue: '60'
    }),
    auto_ortho_stitching: _emberData.default.attr('boolean'),
    accept_restricted_airspace: _emberData.default.attr('boolean'),
    skip_asset_verification: _emberData.default.attr('boolean'),
    skip_instructions_confirmation: _emberData.default.attr('boolean'),
    addons: _emberData.default.hasMany('package-addon', {
      async: false
    }),
    description_bullet_points: _emberData.default.attr('array', {
      defaultValue() {
        return [];
      }
    }),
    sample_link: _emberData.default.attr('string'),
    hasCatalogItems: Ember.computed('catalog_items', 'catalog_items.[]', function () {
      return this.get('catalog_items').length > 0;
    }),
    hasLineCatalogItem: Ember.computed('catalog_items', 'catalog_items.[]', function () {
      return this.get('catalog_items').filter(item => item.supported_geometry === "LineString").length > 0;
    }),
    panoramaShotType: Ember.computed('shot_list.shot_types', function () {
      if (this.get('shot_list.shot_types.length')) {
        return this.get('shot_list.shot_types').find(shot_type => shot_type.template === 'pano_tiles');
      }
      return null;
    }),
    orthoShotType: Ember.computed('shot_list.shot_types', function () {
      if (this.get('shot_list.shot_types.length')) {
        return this.get('shot_list.shot_types').find(shot_type => shot_type.template === 'ortho');
      }
      return null;
    }),
    imageSeriesShotType: Ember.computed('shot_list.shot_types', function () {
      if (this.get('shot_list') && this.get('shot_list.shot_types')) {
        return this.get('shot_list.shot_types').find(shot_type => shot_type.template === 'image_series');
      }
      return null;
    }),
    orbitShotType: Ember.computed('shot_list.shot_types', function () {
      if (this.get('shot_list') && this.get('shot_list.shot_types')) {
        return this.get('shot_list.shot_types').find(shot_type => shot_type.template === 'orbit');
      }
      return null;
    }),
    missionDurationInHours: Ember.computed('mission_duration', function () {
      if (this.get('mission_duration')) {
        return parseInt(this.get('mission_duration')) / 60;
      }
      return null;
    }),
    fullName: Ember.computed('vertical.short_name', 'name', 'price', function () {
      return `${this.get('vertical.short_name')} ${this.get('name')} ${this.get('currency.symbol')}${this.get('price') / 100}`;
    }),
    tablets: Ember.computed('devices.@each.device_type', function () {
      return this.get('devices').filter(device => device.get('device_type') === 'tablet');
    }),
    phones: Ember.computed('devices.@each.device_type', function () {
      return this.get('devices').filter(device => device.get('device_type') === 'phone');
    })
  });
});