define("admin/models/three-d-model", ["exports", "ember-data", "admin/models/shareable"], function (_exports, _emberData, _shareable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _shareable.default.extend({
    mission: _emberData.default.belongsTo('mission'),
    shot: _emberData.default.belongsTo('shot'),
    name: _emberData.default.attr('string'),
    processing_status: _emberData.default.attr('string'),
    remote_url: _emberData.default.attr('string'),
    source_type: _emberData.default.attr('string'),
    downloadableGltf: Ember.computed('remote_url', function () {
      if (this.get('remote_url')) return `${this.get('remote_url')}`;
      return;
    })
  });
});